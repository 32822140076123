<template>
    <div class="PointsMall">
        <div class="PointsMall-head">
             <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="PointsMall-middle">
            <div class="PointsMall-search">
                <input placeholder="想找点啥？"/>
                <i class="iconfont icon-sousuo"></i>
            </div>
            <div class="PointsMall-use">
                <p>
                    <span>当前可用积分</span>
                     <i class="iconfont icon-jinrujiantou"></i>
                </p>
                <p>
                    <span>2500</span>
                    <span>积分</span>
                </p>
            </div>
        </div>
        <div class="PointsMall-content">
            <div class="tabs">
                <div :class="{Tactive : itemIndex == 1}" @click="toggleTab(1)">生活用品</div>
                <div :class="{Tactive : itemIndex == 2}" @click="toggleTab(2)">个人家户</div>
                <div :class="{Tactive : itemIndex == 3}" @click="toggleTab(3)">推荐</div>
                <div :class="{Tactive : itemIndex == 4}" @click="toggleTab(4)">粮油副食</div>
                <div :class="{Tactive : itemIndex == 5}" @click="toggleTab(5)">用眼卫生</div>
            </div>
            <!-- 生活用品 -->
            <live :data="data"/>
            <!-- 个人家户 -->
            <!-- 推荐 -->
            <!-- 粮油副食 -->
            <!-- 用眼卫生 -->
        </div>
        <!-- navBar组件 -->
        <nav-bar />
    </div>
</template>

<script>
import live from '@/views/life.vue';
import NavBar from '../components/navBar.vue';
export default {
    name: 'PointsMall',
    data() {
        return{
            itemIndex: 3,
            data: [{
                    src: require(`@/assets/images/shop/u77.png`),
                    title: '定制保温杯',
                    price: 50
                },
                {
                    src: require(`@/assets/images/shop/u94.png`),
                    title: '精品墨镜',
                    price: 150
                },
                {
                    src: require(`@/assets/images/shop/u95.png`),
                    title: '华为智能耳机',
                    price: 10000
                },
                {
                    src: require(`@/assets/images/shop/u96.png`),
                    title: '品牌山地自行车',
                    price: 800
                }],
            lifeList: [
                {
                    src: require(`@/assets/images/shop/u77.png`),
                    title: '定制保温杯',
                    price: 50
                },
                {
                    src: require(`@/assets/images/shop/u94.png`),
                    title: '精品墨镜',
                    price: 150
                },
                {
                    src: require(`@/assets/images/shop/u95.png`),
                    title: '华为智能耳机',
                    price: 10000
                },
                {
                    src: require(`@/assets/images/shop/u96.png`),
                    title: '品牌山地自行车',
                    price: 800
                }
            ],
            personalList: [],
            foot: [],
            eyesList: [
                {
                    src: require(`@/assets/images/shop/u94.png`),
                    title: '精品墨镜',
                    price: 150
                },
            ],
        }
    },
    components: {
       live,
       NavBar
    },
    methods: {
        goHistotry() {
            this.$router.go(-1)
        },
        toggleTab(n) {
            this.itemIndex = n
            if(n == 1) {
                this.data = this.lifeList
            }else if(n == 2) {
                this.data = this.personalList
            }else if(n == 3) {
                this.data = this.lifeList
            }else if(n == 4) {
                this.data = this.foot
            }else {
                this.data = this.eyesList
            }
        }
    },
}
</script>

<style lang="less" scoped>
@import '../assets/css/PointsMall.less';
</style>