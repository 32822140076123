<template>
    <div>
        <div class="shop" >
            <div class="shop-tips" v-for="item in data" :key="item.id">
                <div class="shop-tips-content">
                    <div class="shop-img">
                        <img :src="item.src" />
                    </div>
                    <div class="shop-title">{{item.title}}</div>
                    <div class="shop-money">
                        <div class="money-info">
                            <i class="iconfont icon-jinbi"></i>
                            <span class="money">{{item.price}}</span>
                        </div>
                        <div class="exchange">兑换</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-show="isShow" class="none">
            <p>暂无兑换商品.....</p>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Life',
    props: {
        data: Array
    },
    data() {
        return {
            // data: []
        }
    },
}
</script>

<style lang="less" scoped>
.shop {
      display: flex;
      flex-flow:row  wrap;
      margin-top:20px;
  }
  .shop-tips {
      box-sizing: border-box;
      width: 49%;
  }
  .shop-img {
      width: 127px;
      height: 98px;
    //   padding: 20px 20px 10px 20px;
    padding-bottom: 10px;
  }
   .shop-tips-content{
      padding: 10px 20px 20px 20px;
  }
  .shop-title {
    padding-bottom:5px;
  }
  .shop-money{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
  .shop-img img{
    //   background-size: 127px 98px;
    width: 127px;
    height: 98px;
  }
  .money-info .iconfont {
    color: rgba(255,197,61)
  }
  .money-info .money{
      font-size: 12px;
  }
  .exchange{
      width: 40px;
      height: 20px;
      line-height: 20px;
      background: rgba(9,187,7);
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      text-align: center;
  }
  .shop-tips:nth-child(2n-1) {
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
  }
    .shop-tips:nth-child(2n) {
        border-bottom: 1px solid #ddd;
    }
    .none {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 30%;
        box-sizing: border-box;
    }
    .none p {
        font-size: 16px;
    }
</style>